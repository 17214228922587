
@import "../node_modules/bootstrap/scss/bootstrap";
@import "../node_modules/@datagrupo/dg-ng-util/assets/scss/dg-ng-util";
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

@import "assets/scss/variables";
@import "assets/scss/typography";
@import "assets/scss/components/cards";
@import "assets/scss/components/tables";
@import "assets/scss/components/inputs";

#sidebar-wrapper {
  background-color: #2f4050 !important;
}

.mat-select-arrow-wrapper {
  display: none !important;
}

